import React, {Component} from 'react'

class Contact extends Component{
    render(){
        return(
            <div>
                Contacts!
            </div>
        )
    }
}

export default Contact