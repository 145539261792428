import React, {Component} from 'react'

class About extends Component{
    render(){
        return(
            <div>
                About
            </div>
        )
    }
}

export default About