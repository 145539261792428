import React, {Component} from 'react'

class Home extends Component{
    render(){
        return(
            <div>
                Home
            </div>
        )
    }
}

export default Home