import React, {Component} from 'react'

class Projects extends Component{
    render(){
        return(
            <div>
                Projects
            </div>
        )
    }
}

export default Projects